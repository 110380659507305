import type { RouteLocationNormalized } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'

// 這幾乎是 composables/analyzeRouteName 的一個拷貝，使用了 to 取代 useRoute 解決 Vue 的一個警告：中間件不推薦使用 useRoute
const _analyzeRouteName = (name: string, to: RouteLocationNormalized) => {
  const siteCode = to?.meta?.siteCode
  const i18nCode = to?.meta?.i18nCode
  if (!siteCode && !i18nCode) return name
  if (siteCode && !i18nCode) return `${siteCode}__${name}`
  return `${siteCode}__${i18nCode}__${name}`
}

// 登錄驗證
export default defineNuxtRouteMiddleware(async (to, from) => {
  // 在服务器端跳过中间件，node服务不储存用户token
  if (process.server) return

  if (useIsLogin()) return

  const nkOriginUrl = useLocalStorage('nk-origin-url', '')
  nkOriginUrl.value = JSON.stringify({
    name: to.name,
    query: to.query,
    params: to.params,
  })
  // 跳转登入頁
  const name = _analyzeRouteName('login', to)
  return navigateTo({ name })
})
